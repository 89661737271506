export const MAX_TITLE_LETTER = 110

let CONF = {
  backEndUrl: 'http://localhost:2337',
  disqus: {
    shortName: 'https-vinance-vn',
    baseUrl: 'http://vinance.vn/',
  },
  fetchCount: 10,
  amazon: {
    baseUrl: 'https://tradex-vn.s3-ap-southeast-1.amazonaws.com',
    performance: '/ai-rating-info/performance{top}.json',
  },
  tutorial: {
    howToEn: 'https://www.youtube.com/watch?v=zvmSdgZv3Gw',
    howToVi: 'https://www.youtube.com/watch?v=fs8wpaZUqIs'
  },
  cardFetchCount: 10,
  css: {
    white: 'rgb(255,255,255)',
    whiteGrey: '#C1C1C1',
    grey: 'rgb(245,245,245)',
    green: 'rgb(0,136,60)',
    yellow: 'rgb(96,84,14)',
    red: 'rgb(235, 15, 41)',
    blue: 'rgb(15,105,255)',
    blue2: 'rgb(11,71,171)',
    black: '#333333',
    orange: 'rgb(255,114,0)',
    zuluGreen: 'rgb(51,153,66)',
    zuluRed: 'rgb(187,43,28)',
  },
  vn30List: [
    'BID',
    'BVH',
    'CTG',
    'FPT',
    'GAS',
    'HDB',
    'HPG',
    'KDH',
    'MBB',
    'MSN',
    'MWG',
    'NVL',
    'PDR',
    'PLX',
    'PNJ',
    'POW',
    'REE',
    'SBT',
    'SSI',
    'STB',
    'TCB',
    'TCH',
    'TPB',
    'VCB',
    'VHM',
    'VIC',
    'VJC',
    'VNM',
    'VPB',
    'VRE',
  ],
}

if (typeof window !== 'undefined' && window['editConfigureFunc'] != null) {
  window['editConfigureFunc'](CONF)
}

export default CONF
