import axios from 'axios'
import CONF from '../config'
import { getLoginInfo } from './localStorage'

const options: any = {
  baseURL: CONF.backEndUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization: {
    //   toString() {
    //     return `Bearer ${getLoginInfo() ? getLoginInfo()?.token : null}`
    //   },
    // },
  },
  timeout: 5000,
}
let axiosProvider = axios.create(options)

if (getLoginInfo() && options.headers.Authorization == null) {
  // console.log(`==========Recreate Axios with token ${getLoginInfo()?.token}`)
  options.headers.Authorization = {
    toString() {
      return `Bearer ${getLoginInfo() ? getLoginInfo()?.token : null}`
    },
  }
  // console.log(`=======options.headers.Authorization======${options.headers.Authorization}`)

  axiosProvider = axios.create(options)
}

if (getLoginInfo() == null && options.headers.Authorization != null) {
  // console.log(`==========Recreate Axios with token ${getLoginInfo()?.token}`)
  options.headers.Authorization = null
  // console.log(`=======options.headers.Authorization======${options.headers.Authorization}`)

  axiosProvider = axios.create(options)
}
// console.log(`=======options.headers.Authorization======${options.headers.Authorization}`)

export default axiosProvider
