import { ILoginInfo } from '../components/Account/interface'
import { LOCAL_STORAGE } from '../constant'

export function getKey<T>(key: string): T | null {
  if (typeof window === 'undefined') return null
  try {
    // let value = null;
    let value
    if (window.localStorage != null) {
      value = window.localStorage.getItem(key)
    }
    if (value == null) {
      return null
    }

    return JSON.parse(value)
    // return value
  } catch (error) {
    return null
  }
}

export function getLoginInfo(): ILoginInfo | null {
  return getKey<ILoginInfo>(LOCAL_STORAGE.LOGIN_INFO)
}

export function setKey<T>(key: string, value: T): void {
  if (typeof window === 'undefined') return

  try {
    if (value != null) {
      if (window.localStorage != null) {
        window.localStorage.setItem(key, JSON.stringify(value))
      }
    }
  } catch (error) {
    return
  }
}

export function removeKey(key: string): void {
  if (typeof window === 'undefined') return

  try {
    if (window.localStorage != null) {
      window.localStorage.removeItem(key)
    }
  } catch (error) {
    return
  }
}
