import { makeStyles } from '@material-ui/core/styles'
import CONF from '../../../../config'

export const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
    // tableLayout: 'fixed',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarButton: {},
  username: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(2.5),
    color: CONF.css.black,
  },
  login: {
    color: CONF.css.black,
  },
  register: {
    color: CONF.css.black,
    marginLeft: theme.spacing(1),
  },
}))
