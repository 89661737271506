import { API, LOCAL_STORAGE } from '../../constant'
import { getAxios, postAxios } from '../../utils/parse'
import { getLoginInfo, removeKey, setKey } from '../../utils/localStorage'
import { createState } from '@hookstate/core'
import { ILoginInfo } from './interface'
import { setObj } from '../../utils/hookState'

export const AccountState = {
  register: createState({}),
  profile: createState('{}'),
  // loginInfo: createState(JSON.stringify(getLoginInfo())),
}

export const AccountAction = {
  login(email: string, password: string) {
    return async () => {
      try {
        removeKey(LOCAL_STORAGE.LOGIN_INFO)
        const response = await postAxios(API.AUTH.login, { identifier: email, password })
        console.log(`======response=======${JSON.stringify(response)}`)
        const loginInfoObj = {
          token: response.jwt,
          username: response.user.username,
          avatar: response.user.avatar,
          email: response.user.email,
        }
        setKey<ILoginInfo>(LOCAL_STORAGE.LOGIN_INFO, loginInfoObj)
        // setObj(AccountState.loginInfo, loginInfoObj)
        // LayoutState.snack.set({ text: `Hello ${response.user.username}`, variant: 'success' })
      } catch (err) {
        removeKey(LOCAL_STORAGE.LOGIN_INFO)
      }
    }
  },
  logout() {
    removeKey(LOCAL_STORAGE.LOGIN_INFO)
    // setObj(AccountState.loginInfo, {})
  },
  register(username: string, email: string, password: string, avatar?: string) {
    return async () => {
      try {
        AccountState.register.set({})

        const registerResponse = await postAxios(API.AUTH.register, {
          username,
          email,
          password,
          avatar,
        })

        AccountState.register.set(registerResponse)
      } catch (err) {
        AccountState.register.set({})
      }
    }
  },

  getProfile() {
    return async () => {
      try {
        setObj(AccountState.profile, {})

        const loginInfo = getLoginInfo()
        const response = await getAxios(API.AUTH.profile, {
          auth: loginInfo?.token,
        })

        // console.log(`=========getProfile==========${JSON.stringify(response)}`)

        setObj(AccountState.profile, response)
      } catch (err) {
        setObj(AccountState.profile, {})
      }
    }
  },
  updateUserInfo(username: string, bio: string, avatar: string = '') {
    return async () => {
      try {
        const params = { username, bio, avatar }
        console.log(`===params=======${JSON.stringify(params)}`)
        await postAxios(API.ACCOUNT.updateUserInfo, params)
      } catch (err) {
        console.log(err)
      }
    }
  },
  updateTlg(tlg_username: string) {
    return async () => {
      try {
        const params = { tlg_username }
        await postAxios(API.ACCOUNT.updateTlg, params)
      } catch (err) {
        console.log(err)
      }
    }
  },
}
