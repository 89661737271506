import React, { useEffect } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'
import { LanguageAction, LanguageState } from './state'
import Typography from '@material-ui/core/Typography'
import { getKey, setKey } from '../../../utils/localStorage'
import { useState } from '@hookstate/core'

export default function SelectLanguage() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { t, i18n } = useTranslation()
  const languageState = useState(LanguageState.language)

  useEffect(() => {
    const lastLanguage: { language: string } | null = getKey('language')
    if (lastLanguage != null) {
      LanguageAction.changeLanguageAction(lastLanguage?.language)()
      i18n.changeLanguage(lastLanguage?.language)
    }
    i18n.changeLanguage()
  }, [])

  const changeToEn = () => {
    LanguageAction.changeLanguageAction('en')()
    i18n.changeLanguage('en')
    setKey('language', { language: 'en' })
    setAnchorEl(null)
  }
  const changeToVn = () => {
    LanguageAction.changeLanguageAction('vi')()
    i18n.changeLanguage('vi')
    setKey('language', { language: 'vi' })

    setAnchorEl(null)
  }
  // const changeToKr = () => {
  //   LanguageState.changeLanguageAction('kr')()
  //   i18n.changeLanguage('kr')
  //   setAnchorEl(null)
  // }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Typography className={classes.changeLanguageButton} onClick={handleClick}>
        {languageState.get().toUpperCase()}
      </Typography>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={changeToVn}>Tiếng Việt</MenuItem>
        <MenuItem onClick={changeToEn}>English</MenuItem>
        {/*<MenuItem onClick={changeToKr}>Korean</MenuItem>*/}
      </Menu>
    </div>
  )
}
