import { createState } from '@hookstate/core'
import axiosProvider from '../../utils/axiosProvider'
import { ISnackState } from './SnackBar/interface'

export const SearchUrl = {
  search: 'searches/search',
}

export const LayoutState = {
  searchText: createState<string>(''),
  snack: createState<ISnackState>({
    text: '',
    variant: 'default',
  }),
}

export const LayoutAction = {
  searchAction(keyword: string) {
    return async () => {
      const { data: abc2 } = await axiosProvider.post(SearchUrl.search, { keyword })
      console.log(`=====abc2====${JSON.stringify(abc2)}`)
      searchResultUrlState.set(abc2)
    }
  },
}

export const searchResultUrlState = createState('')

export const searchAction = (keyword: string) => {
  return async () => {
    const { data: abc2 } = await axiosProvider.post(SearchUrl.search, { keyword })
    console.log(`=====abc2====${JSON.stringify(abc2)}`)
    searchResultUrlState.set(abc2)
  }
}
