import axiosProvider from './axiosProvider'
import { useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { METHODS, SNACK_BAR_VARIANT, TECHX_DATE_FORMAT } from '../constant'
import { SNACKBAR_MESSAGE_LIST } from '../globals/variable'
import CONF from '../config'
import axios from 'axios'
import { getLoginInfo } from './localStorage'

const qs = require('qs')

const moment = require('moment')

export const truncate = (
  str: string,
  n: number,
  useWordBoundary: boolean = true,
  usePeriodBoundary: boolean = false,
  isEllipsis: boolean = true
) => {
  if (str.length <= n) {
    return str
  }

  let result = ''
  const ellipsis = '...'
  const subString = str.substr(0, n - 1) // the original check

  if (useWordBoundary) result = subString.substr(0, subString.lastIndexOf(' '))
  else if (usePeriodBoundary) result = subString.substr(0, subString.lastIndexOf('.') + 1)
  else result = subString

  return isEllipsis ? result + ellipsis : result
}

export const getReducer = (state, action, initState, successState, failState) => {
  switch (action.type) {
    case initState:
      return { ...state, loading: true }
    case successState:
      return { ...state, loading: false, data: action.payload.data, errorMessage: undefined }
    case failState:
      return {
        ...state,
        loading: false,
        data: [],
        errorMessage: action.payload.errorMessage,
      }
    default:
      return state
  }
}

export const getStartOfDate = (date) => {
  const temp = new Date(date.getTime())
  temp.setHours(0, 0, 0, 0)
  return temp
}

export const numberWithCommas = (x) => {
  if (x == null) {
    return null
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const parseError = (e: any, t: any) => {
  return 'Something went wrong'
}

export const countDateSincePublish = (created_at) => {
  // console.log(`====created_at======${created_at}`)
  if (created_at == null) {
    return ''
  }
  return Math.round(Math.abs((+new Date(created_at) - +new Date()) / (24 * 60 * 60 * 1000)))
}

export const formatDateToDisplay = (date, format = TECHX_DATE_FORMAT) => {
  try {
    if (date == null) {
      return null
    }
    const obj = moment(date)
    if (obj.isValid()) {
      return moment.utc(date).format(format)
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

// plus 7, format date to display
export const toVnDt = (date): string => {
  const format = 'DD/MM/YYYY HH:mm:ss'
  try {
    if (date == null) {
      return ''
    }

    const obj = moment(date)
    if (obj.isValid()) {
      const vnTime = new Date(date)
      vnTime.setHours(vnTime.getHours() + 7)

      return moment.utc(vnTime).format(format)
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

export const convertStringToDate = (data, format = TECHX_DATE_FORMAT) => {
  try {
    const obj = moment.utc(data, format)
    if (obj.isValid()) {
      return obj.toDate()
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

export const getRest = async (url, parameter, headers = {}) => {
  const processed_url = `${url}?${qs.stringify(parameter)}`

  // eslint-disable-next-line no-undef
  const fetchedData = await fetch(processed_url, {
    method: 'GET',
    headers: headers,
  })
  if (fetchedData.status !== 200) {
    return null
  }
  return fetchedData.json()
}

export const getAxios = async (url, parameter = {}) => {
  const { data: response } = await axiosProvider({
    url: url,
    method: METHODS.GET,
    params: parameter,
  })
  if (response.strapi_error_message != null && response.strapi_error_message !== '') {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_error_message, variant: 'error' })
    throw new Error('Failed')
  }
  if (response.strapi_variant != null && response.strapi_message != null) {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_message, variant: response.strapi_variant })
    if (response.strapi_variant === SNACK_BAR_VARIANT.ERROR) {
      throw new Error(response.strapi_message)
    }
  }

  return response
}

export const postAxios = async (url, parameter) => {
  const options: any = {
    baseURL: CONF.backEndUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    timeout: 5000,
  }
  if (getLoginInfo()) {
    options.headers.Authorization = `Bearer ${getLoginInfo()?.token}`
  }
  const axiosProvider = axios.create(options)

  const { data: response } = await axiosProvider.post(url, parameter, { maxRedirects: 0 })

  if (response.strapi_error_message != null && response.strapi_error_message !== '') {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_error_message, variant: 'error' })
    throw new Error('Failed')
  }
  if (response.strapi_variant != null && response.strapi_message != null) {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_message, variant: response.strapi_variant })
    if (response.strapi_variant === SNACK_BAR_VARIANT.ERROR) {
      throw new Error(response.strapi_message)
    }
  }

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }
  return response
}

export const putAxios = async (url, parameter) => {
  const { data: response } = await axiosProvider.put(url, parameter)
  if (response.strapi_error_message != null && response.strapi_error_message !== '') {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_error_message, variant: 'error' })
    throw new Error('Failed')
  }
  if (response.strapi_variant != null && response.strapi_message != null) {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_message, variant: response.strapi_variant })
    if (response.strapi_variant === SNACK_BAR_VARIANT.ERROR) {
      throw new Error(response.strapi_message)
    }
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }
  return response
}

export const deleteAxios = async (url, parameter = {}) => {
  const { data: response } = await axiosProvider.delete(url, parameter)
  if (response.strapi_error_message != null && response.strapi_error_message !== '') {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_error_message, variant: 'error' })

    throw new Error('Failed')
  }
  if (response.strapi_variant != null && response.strapi_message != null) {
    SNACKBAR_MESSAGE_LIST.push({ text: response.strapi_message, variant: response.strapi_variant })
    if (response.strapi_variant === SNACK_BAR_VARIANT.ERROR) {
      throw new Error(response.strapi_message)
    }
  }
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }
  return response
}

export const isScreenBiggerThan = (breakPoint) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakPoint))
}

export const paddingNumber = (value) => {
  if (value >= 0) {
    // 1 digit
    if (value < 10) {
      return `\u00a0\u00a0${value}`
    } else {
      return `\u00a0${value}`
    }
  } else {
    // 1 digit
    if (value > -10) {
      return `\u00a0${value}`
    } else {
      return `${value}`
    }
  }
}

export const toPc = (value) => {
  // let roe = stockRatioState.roe?.ornull?.amount?.ornull?.get()
  if (value != null) {
    value = +value * 100
  }
  const abc = value?.toFixed(2)
  if (abc != null) {
    return `${abc}%`
  }
  return null
}

export const isDev = () => {
  const abc = process.env.IS_DEV
  // console.log(`====abc===${abc}`)
  if (abc != null) {
    return +abc === 1
  }
  return false
}

export function weeksBetween(d1, d2) {
  try {
    // console.log(`====d1====${d1}`)
    const abc = Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000))
    if (abc != null && !isNaN(abc)) {
      return abc
    }
  } catch (e) {}
  return -1
}

export const isObjEmpty = (obj) => {
  if (obj == null) {
    return true
  } else {
    if (obj.constructor === Object && Object.keys(obj).length === 0) {
      return true
    }
  }
  return false
}

export const toVnTime = (dt: Date) => {
  try {
    const vnTime = new Date(dt)
    vnTime.setHours(dt.getHours() + 7)
    return vnTime
  } catch (e) {
    return dt
  }
}
