import * as React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import { navigate } from 'gatsby'
import AvatarMenu from '../../Account/AvatarMenu/AvatarMenu'
import { AppBar } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './style'
import SelectLanguage from './SelectLanguage'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { LayoutAction, LayoutState, searchResultUrlState } from '../state'
import { isScreenBiggerThan } from '../../../utils/parse'
import TemporaryDrawer from './mobile/SideMenu'
import { useMobileStyles } from './mobileStyle'
import { ROUTES } from '../../../constant'
import { useState } from '@hookstate/core'

export default function Header() {
  const classes = useStyles()
  const mobileClasses = useMobileStyles()
  const { t, i18n } = useTranslation()

  const searchTextState = useState(LayoutState.searchText)

  const [openSearch, setOpenSearch] = React.useState<boolean>(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenSearch(!openSearch)
  }
  const sections = [
    { title: t('Home'), url: ROUTES.HOME },
    { title: t('Investing Basics'), url: ROUTES.EDUCATION },
    { title: t('Strategy'), url: ROUTES.STRATEGY },
    // { title: t('Virtual Trading'), url: ROUTES.VIRTUAL_TRADING },
  ]

  const handleSearchChange = (event) => {
    searchTextState.set(event.target.value)

    if (event.target.value.length >= 3) {
      LayoutAction.searchAction(event.target.value)()
      searchTextState.set('')
      console.log(`=====event======${event.target.value}`)
      console.log(`=====searchResultUrlState======${searchResultUrlState.get()}`)
    }
  }

  const handleMobileClickAway = () => {
    setOpenSearch(!openSearch)
  }

  const handleDesktopClickAway = () => {
    searchTextState.set('')
  }

  return isScreenBiggerThan('md') ? (
    <AppBar position="sticky" className={classes.appBar}>
      <Container>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          <Grid container>
            <Grid container item sm={6} justify="space-around" alignItems="center">
              {sections.map((section) => (
                <Typography className={classes.menuText} key={section.title} onClick={() => navigate(section.url)}>
                  {section.title}
                </Typography>
              ))}
            </Grid>

            <Grid container item sm={6} justify="flex-end" alignItems="center">
              {/*<div className={classes.search}>*/}
              {/*  <div className={classes.searchIcon}>*/}
              {/*    <SearchIcon />*/}
              {/*  </div>*/}
              {/*  <ClickAwayListener onClickAway={handleDesktopClickAway}>*/}
              {/*    <InputBase*/}
              {/*      placeholder={t('Search…')}*/}
              {/*      classes={{*/}
              {/*        root: classes.inputRoot,*/}
              {/*        input: classes.inputInput,*/}
              {/*      }}*/}
              {/*      autoFocus={true}*/}
              {/*      value={searchTextState.get()}*/}
              {/*      inputProps={{ 'aria-label': 'search' }}*/}
              {/*      onChange={handleSearchChange}*/}
              {/*      onKeyPress={(ev) => {*/}
              {/*        console.log(`Pressed keyCode ${ev.key}`)*/}
              {/*        if (ev.key === 'Enter') {*/}
              {/*          if (searchResultUrlState.get() === '') {*/}
              {/*            navigate('/')*/}
              {/*          } else {*/}
              {/*            navigate(searchResultUrlState.get())*/}
              {/*          }*/}
              {/*        }*/}
              {/*      }}*/}
              {/*    />*/}
              {/*  </ClickAwayListener>*/}
              {/*</div>*/}
              <div style={{ marginLeft: '30px', marginRight: '30px' }}>
                <AvatarMenu />
              </div>
              {/*<div style={{ display: 'inline-block' }}>*/}
              <SelectLanguage />
              {/*</div>*/}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  ) : (
    <AppBar position="sticky" className={mobileClasses.appBar}>
      <Toolbar component="nav" variant="dense" className={mobileClasses.toolbarSecondary}>
        <Grid container>
          <Grid container item xs={1} justify="space-around" alignItems="center">
            <TemporaryDrawer />
          </Grid>

          <Grid container item xs={10} justify="center" alignItems="center">
            <Typography className={mobileClasses.mobileTitle} onClick={() => navigate(ROUTES.HOME)}>
              Vinance
            </Typography>
            {/*{!openSearch ? (*/}
            {/*  <Typography className={mobileClasses.mobileTitle} onClick={() => navigate(ROUTES.HOME)}>*/}
            {/*    Vinance*/}
            {/*  </Typography>*/}
            {/*) : (*/}
            {/*  <ClickAwayListener onClickAway={handleMobileClickAway}>*/}
            {/*    <TextField*/}
            {/*      className={mobileClasses.searchText}*/}
            {/*      type="search"*/}
            {/*      placeholder={t('Search…')}*/}
            {/*      autoFocus={true}*/}
            {/*      // label={t('Stock')}*/}
            {/*      // value={stock}*/}
            {/*      onChange={handleSearchChange}*/}
            {/*      onKeyPress={(ev) => {*/}
            {/*        console.log(`Pressed keyCode ${ev.key}`)*/}
            {/*        if (ev.key === 'Enter') {*/}
            {/*          // Do code here*/}
            {/*          if (searchResultUrlState.get() === '') {*/}
            {/*            navigate('/')*/}
            {/*            // window.location.reload()*/}
            {/*          } else {*/}
            {/*            navigate(searchResultUrlState.get())*/}
            {/*            // window.location.reload()*/}
            {/*          }*/}

            {/*          // ev.preventDefault();*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </ClickAwayListener>*/}
            {/*)}*/}
          </Grid>

          {/*<Grid container item xs={1} justify="center" alignItems="center">*/}
          {/*<IconButton*/}
          {/*  className={mobileClasses.searchIcon}*/}
          {/*  edge="start"*/}
          {/*  color="inherit"*/}
          {/*  aria-label="menu"*/}
          {/*  onClick={handleClick}*/}
          {/*>*/}
          {/*  <SearchIcon />*/}
          {/*</IconButton>*/}
          {/*</Grid>*/}

          <Grid container item xs={1} justify="space-around" alignItems="center">
            <SelectLanguage />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
