import { makeStyles } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 650,
    // tableLayout: 'fixed',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  username: {
    marginLeft: theme.spacing(2)
  },
  loginTypo: {
    // textDecorationColor: 'rgb(7, 15, 108)',
    // textDecorationColor: CONF.css.whiteGrey,
    // fontSize: '13px',
    // fontWeight: 700,
    // lineHeight: '42px',
    //
    // backgroundColor: 'rgba(0, 0, 0, 0)',
    // color: 'rgb(7, 15, 108)',
    color: CONF.css.whiteGrey,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  registerTypo: {
    // textDecorationColor: 'rgb(7, 15, 108)',
    // textDecorationColor: CONF.css.whiteGrey,
    // fontSize: '13px',
    // fontWeight: 700,
    // lineHeight: '42px',
    //
    // backgroundColor: 'rgba(0, 0, 0, 0)',
    // color: 'rgb(7, 15, 108)',
    color: CONF.css.whiteGrey,
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
  },
}))
