import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../../constant'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { AccountAction } from '../../../Account/state'
import { getLoginInfo } from '../../../../utils/localStorage'
import HomeIcon from '@material-ui/icons/Home'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import AvatarMobile from './AvatarMobile'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AndroidIcon from '@material-ui/icons/Android'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
})

type Anchor = 'top' | 'left' | 'bottom' | 'right'

export default function TemporaryDrawer() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {getLoginInfo() ? (
        <List>
          <ListItem button key={'Home'}>
            <ListItemIcon>
              <AvatarMobile />
            </ListItemIcon>
            {/*<ListItemText primary={getLoginInfo()?.username} />*/}
          </ListItem>

          <ListItem
            button
            key={'Logged out'}
            onClick={() => {
              AccountAction.logout()
              navigate(ROUTES.HOME)
              window.location.reload()
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={'Logged out'} />
          </ListItem>
        </List>
      ) : (
        <List>
          <ListItem
            button
            key={'1'}
            onClick={() => {
              navigate(ROUTES.LOGIN)
            }}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={'Login'} />
          </ListItem>

          <ListItem
            button
            key={'2'}
            onClick={() => {
              navigate(ROUTES.REGISTER)
            }}
          >
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary={'Register'} />
          </ListItem>
        </List>
      )}

      <Divider />
      <List>
        <ListItem
          button
          key={'Home'}
          onClick={() => {
            navigate(ROUTES.HOME)
            // window.location.reload()
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={'Home'} />
        </ListItem>

        <ListItem
          button
          key={'Education'}
          onClick={() => {
            navigate(ROUTES.EDUCATION)
            // window.location.reload()
          }}
        >
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText primary={'Education'} />
        </ListItem>

        <ListItem
          button
          key={'Strategy'}
          onClick={() => {
            navigate(ROUTES.STRATEGY)
          }}
        >
          <ListItemIcon>
            <AndroidIcon />
          </ListItemIcon>
          <ListItemText primary={'Strategy'} />
        </ListItem>
      </List>
    </div>
  )

  return (
    <div>
      {(['left'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            edge="start"
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(anchor, true)}
          >
            <MenuIcon />
          </IconButton>
          {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}
