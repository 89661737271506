import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Avatar } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../constant'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'
import { AccountAction } from '../state'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { getLoginInfo } from '../../../utils/localStorage'
import { SNACKBAR_MESSAGE_LIST } from '../../../globals/variable'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem)

export default function AvatarMenu() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickProfile = () => {
    navigate(ROUTES.PROFILE)
    // window.location.reload()
  }

  const handleSetAnchor = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClickLogOut = () => {
    AccountAction.logout()
    const abc = getLoginInfo()
    console.log(`======abc======${JSON.stringify(abc)}`)
    if (abc == null) {
      SNACKBAR_MESSAGE_LIST.push({ text: t('Logged out'), variant: 'warning' })
    }
    navigate(ROUTES.HOME)
    setTimeout(function () {
      // navigate(ROUTES.HOME)
      window.location.reload()
    }, 1000)
  }

  return !getLoginInfo() ? (
    <Grid container justify="space-around">
      <Typography className={classes.loginTypo} onClick={(e) => navigate(ROUTES.LOGIN)}>
        {t('Login')}
      </Typography>
      <Typography className={classes.registerTypo} onClick={(e) => navigate(ROUTES.REGISTER)}>
        {t('Register')}
      </Typography>
    </Grid>
  ) : (
    <div>
      <Avatar src={getLoginInfo()?.avatar} className={classes.avatar} onClick={handleSetAnchor} />

      <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <StyledMenuItem disabled>
          {/*<ListItemIcon>*/}
          {/*  <InboxIcon fontSize="small" />*/}
          {/*</ListItemIcon>*/}
          <ListItemText className={classes.username} primary={getLoginInfo()?.username} />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickProfile}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={t('User Profile')} />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleClickLogOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('Log out')} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}
