import { createState } from '@hookstate/core'

export const LanguageState = {
  language: createState('vi'),
}

export const LanguageAction = {
  changeLanguageAction(lgg: string) {
    return async () => {
      LanguageState.language.set(lgg)
    }
  },
}
