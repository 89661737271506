import { makeStyles } from '@material-ui/core/styles'
import CONF from '../../../config'

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  appBar: {
    // color: 'rgb(0, 0, 0)',
    // boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 10px 0px',
    boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px',
    //
    // backgroundImage: 'linear-gradient(rgb(249, 249, 249), rgb(235, 232, 239))',
    backgroundColor: CONF.css.black,
    // backgroundColor: 'rgb(228,228,228)',
  },
  mobileTitle: {
    // alignItems: 'center'
  },
  sectionDesktop: {
    display: 'inline-block',
    // [theme.breakpoints.up('md')]: {
    //   display: 'flex',
    // },
  },
  menuText: {
    // textDecorationColor: 'rgb(7, 15, 108)',
    textDecorationColor: CONF.css.whiteGrey,
    fontSize: '16px',
    fontWeight: 700,
    // lineHeight: '42px',

    backgroundColor: 'rgba(0, 0, 0, 0)',
    // color: 'rgb(7, 15, 108)',
    color: CONF.css.whiteGrey,
    cursor: 'pointer',
  },

  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // '&:hover': {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    backgroundColor: CONF.css.white,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchText: {
    backgroundColor: CONF.css.white,
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    // paddingLeft: theme.spacing(2)
    // margin: theme.spacing(1)
  },
  searchIcon: {
    color: '#757575',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    // color: 'inherit',
    // color: CONF.css.whiteGrey,
    color: '#757575',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    // color: CONF.css.whiteGrey,
    color: '#757575',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  changeLanguageButton: {
    color: CONF.css.whiteGrey,
    fontWeight: 700,
    fontSize: '14px',
    cursor: 'pointer',
  },
}))
