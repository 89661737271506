import React, { useEffect } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { useState } from '@hookstate/core'
import { LayoutState } from '../state'
import { SNACKBAR_MESSAGE_LIST } from '../../../globals/variable'
import { ISnackState } from './interface'
import { useTranslation } from 'react-i18next'

function MyApp() {
  const { enqueueSnackbar } = useSnackbar()
  const { t, i18n } = useTranslation()

  const snackState = useState(LayoutState.snack)
  const [time, setTime] = React.useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 500)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (snackState.text.get() !== '') {
      enqueueSnackbar(snackState.text.get(), { variant: snackState.variant.get() })
      snackState.set({ text: '', variant: 'default' })
    }

    if (SNACKBAR_MESSAGE_LIST.length > 0) {
      const firstSnackBar: ISnackState | undefined = SNACKBAR_MESSAGE_LIST.shift()
      if (firstSnackBar != null) {
        enqueueSnackbar(t(firstSnackBar.text), { variant: firstSnackBar.variant })
      }
    }
  }, [snackState, SNACKBAR_MESSAGE_LIST])

  return (
    <React.Fragment>
      {/*<Button onClick={handleClick}>Show snackbar</Button>*/}
      {/*<Button onClick={handleClickVariant('success')}>Show success snackbar</Button>*/}
    </React.Fragment>
  )
}

export default function SnackBarNotification() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  )
}
