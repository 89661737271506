import React from 'react'
import Button from '@material-ui/core/Button'
import { Avatar } from '@material-ui/core'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'
import { ROUTES } from '../../../../constant'
import { getLoginInfo } from '../../../../utils/localStorage'
import Typography from '@material-ui/core/Typography'

export default function AvatarMobile() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  return (
    <>
      <Avatar src={getLoginInfo()?.avatar} className={classes.avatar} onClick={() => navigate(ROUTES.PROFILE)} />
      <Typography className={classes.username} onClick={() => navigate(ROUTES.PROFILE)}>
        {getLoginInfo()?.username}
      </Typography>
    </>
  )
}
