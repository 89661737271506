export const TECHX_DATE_FORMAT = 'YYYYMMDD'
export const TECHX_DATETIME_FORMAT = 'YYYYMMDDhhmmss'
export const STRAPI_DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY'
export const DATETIME_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm'

export const ROUTES = {
  HOME: `/`,
  HOME_NEWS: `/news`,
  POST: `/post`,
  CODE: `/code`,
  EDUCATION: `/education`,
  CONTENT_ADMIN: `/content-admin`,
  EDUCATION_EDIT: `/edit-education`,
  STRATEGY: `/strategy`,
  VIRTUAL_TRADING: `/virtual-trading`,
  REGISTER: '/register',
  LOGIN: '/login',
  HOME_CREATE_POST: `/create-post`,
  HOME_EDIT_POST: `/edit-post`,
  PROFILE: `/profile`,
  WHAT_IS_AI_RATING: '/what-is-ai-rating',
}

export const CONTENT_ADMIN_ROUTES = {
  STRATEGY: `/strategy`,
  SIGNAL: `/signal`,
  STRATEGY_EDIT: `/edit-strategy`,
  SIGNAL_EDIT: `/edit-signal`,
  STRATEGY_CREATE: `/create-strategy`,
  SIGNAL_CREATE: `/create-signal`,
}

export const EDUCATION_POST = {
  HowToUseVi: '/huong-dan-cach-thuc-giao-dich-theo-ai-rating',
  HowToUseEn: '/how-to-use-ai-rating-en',
}

export const METHODS: any = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
}

export const DATE_FORMAT = 'DD/MM/YYYY'

export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A'

export const DATE_TIME_DB = 'YYYY-MM-DD'

export const DATE_NORMAL_FORMAT = 'D/M/YYYY'

export const DATE_QUERY_FORMAT = 'YYYYMMDD'

export const DATE_TIME_QUERY_FORMAT = 'YYYYMMDDHHmm'

export const DEFAULT_FETCH_COUNT = 10

export const POST_TECH_SCORE_SLUG = 'technical-score'

export const POST_QUALITY_SCORE_SLUG = 'quality-score'

export const POST_VALUATION_SCORE_SLUG = 'valuation-score'

export const API = {
  ACCOUNT: {
    updateTlg: '/accounts/updateTelegram',
    updateUserInfo: '/accounts/updateUserInfo',
    getSubscribeList: '/accounts/getSubscribeList',
  },
  POST: {
    getPostList: 'posts',
    getPostCount: 'posts/count',
    getPostDetail: (id: string) => `posts/slug/${id}`,
    postCreate: 'posts/createPost',
    putPostEdit: (id: string) => `posts/${id}`,
    addView: 'posts/addView',
    upvote: 'posts/upvote',
    downvote: 'posts/downvote',
  },
  EDUCATION: {
    getList: 'educations',
    count: 'educations/count',
    findOne: (id: string) => `educations/slug/${id}`,
    create: 'educations/createCustom',
    update: (id: string) => `educations/${id}`,
    addView: 'educations/addView',
    upvote: 'educations/upvote',
    downvote: 'educations/downvote',
  },
  STOCK_DETAIL: {
    getRatio: 'stock-page-financial-ratios/getRatio',
    getStockInfo: 'stock-infos/getStockInfo',
  },
  NEWS: {
    getNewsList: 'news-ratings',
    getNewsCount: 'news-ratings/count',
    getPostDetail: (id: string) => `posts/slug/${id}`,
    postCreate: 'posts/createPost',
    putPostEdit: (id: string) => `posts/${id}`,
    addView: 'posts/addView',
    upvote: 'posts/upvote',
    downvote: 'posts/downvote',
  },
  AUTH: {
    login: 'auth/local',
    register: 'auth/local/register',
    profile: 'users/me',
  },
  AI_RATING: {
    getAiRatingList: 'top-ai-rating/find',
    getAiRatingCount: 'top-ai-rating/count',
    getInOut: 'top-ai-rating/getInOut',
  },
  STRATEGY: {
    findAndCount: 'strategies/find',
    findOne: 'strategies/findOne',
    // findOne: (id: string) => `strategies/${id}`,
    count: 'strategies/count',
    create: 'strategies',
    update: 'strategies/update',
    active: 'strategies/active',
    suspend: 'strategies/suspend',
    follow: 'strategies/follow',
    unfollow: 'strategies/unfollow',
    addView: 'strategies/addView',
    deleteZ: (id: string) => `strategies/${id}`,
    // update: (id: string) => `strategies/${id}`,
  },
  GALLERY: {
    find: 'gallery-strategies/find',
    update: 'gallery-strategies/update',
  },
  SIGNAL: {
    find: 'signals/find',
    count: 'signals/count',
    create: 'signals',
    active: 'signals/active',
    suspend: 'signals/suspend',
    deleteZ: (id: string) => `signals/${id}`,
    update: (id: string) => `signals/${id}`,
    findTradingHistory: 'signals/getClosedTrade',
    countTradingHistory: 'signals/countClosedTrade',
    findOpenPosition: 'signals/getOpeningTrade',
    countOpenPosition: 'signals/countOpeningTrade',
  },
  DAILY_PROFIT: {
    find: 'daily-profits/find',
  },
  BACK_TEST: {
    find: 'back-test-data',
  },
  CHART: {
    getPerformance: 'rating-performances/find',
    getVnIndex: 'charts/vnIndex',
  },
  PAYMENT: {
    postVnPay: 'payments/vnPay',
    postMomo: 'payments/momo',
    postZaloPay: 'payments/zaloPay',
  },
  DEMO_ACCOUNT: {
    buyStock: 'demo-portfolios/buyStock',
    checkStockPrice: 'demo-portfolios/checkStockPrice',
    getPortfolioList: 'demo-portfolios/getPortfolioList',
    countPortfolioList: 'demo-portfolios/countPortfolioList',
  },
}

export const getApiUrl = (api: { url: string | Function; method?: string }, ctx?: { id: string }): string => {
  if (typeof api.url === 'string') {
    return api.url
  } else {
    return api.url(ctx)
  }
}

export enum PAYMENT_METHOD_ENUM {
  MOMO = 'MOMO',
  VNPAY = 'VNPAY',
  ZALOPAY = 'ZALOPAY',
  CREDIT = 'CREDIT',
}

export const DIVIDEND_NEWS_CATEGORY = {
  INSIDER_BUY: 'INSIDER_BUY',
  INSIDER_SELL: 'INSIDER_SELL',
  DIVIDEND_STOCK: 'DIVIDEND_STOCK',
  DIVIDEND_CASH: 'DIVIDEND_CASH',
  DIVIDEND_UNCATEGORIZED: 'DIVIDEND_UNCATEGORIZED',
  ADDITIONAL_ISSUE: 'ADDITIONAL_ISSUE',
  TREASURY_SHARES: 'TREASURY_SHARES',
  ESOP: 'ESOP',
  PRIVATE_OFFERING: 'PRIVATE_OFFERING',
  PREFERRED_SHARES: 'PREFERRED_SHARES',
  TODAY: 'TODAY',
}

export const SELL_BUY_ACTIONS = {
  STRONG_BUY: 'STRONG BUY',
  BUY: 'BUY',
  NEUTRAL: 'NEUTRAL',
  SELL: 'SELL',
  STRONG_SELL: 'STRONG SELL',
}

export const RATING_SELL_BUY_ACTIONS = ['STRONG SELL', 'SELL', 'NEUTRAL', 'BUY', 'STRONG BUY']

export const DIVIDEND_NEWS_ERROR_STATUS = {
  DUPLICATED: 'DUPLICATED',
  NO_NUMBER: 'NO_NUMBER',
  MANUAL_REMOVE: 'MANUAL_REMOVE',
}
export const REST_URL = {
  EDUCATION: {
    getList: 'educations',
    count: 'educations/count',
    findOne: (id: string) => `educations/slug/${id}`,
    create: 'educations/createCustom',
    update: (id: string) => `educations/${id}`,
    addView: 'educations/addView',
    upvote: 'educations/upvote',
    downvote: 'educations/downvote',
  },
}

export const LOCAL_STORAGE = {
  LOGIN_INFO: 'LOGIN_INFO',
}

export const CATEGORY_LIST = ['Technical', 'Valuation', 'Trading Basics']

export const TEST_AVATAR = 'https://lh3.googleusercontent.com/a-/AOh14Gji121L-bEj6049-Gs2WcbikwAi8sGJfaowGzKvfg=s96-c'

export const QUILL_MODULE = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
}

export const QUILL_FORMAT = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]

export const STRATEGY_TYPE_ENUM = ['EQUITY', 'DERIVATIVES']
export const SELL_BUY_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
}

export const CONST = {
  STRATEGY_TYPE: {
    EQUITY: 'EQUITY',
    DERIVATIVES: 'DERIVATIVES',
  },
}

export const SNACK_BAR_VARIANT = {
  DEFAULT: 'default',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
}